
import { defineComponent } from 'vue';
import KTDataTable from '@/components/datatables/KTDataTable.vue';
import useBreakpoints from 'vue-next-breakpoints';
import NewFolder from '@/views/new-design/pages/Settings/Assets/components/NewFolder.vue';
import DropDownOption from '@/views/new-design/pages/Settings/Assets/components/DropDownOption.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  Getters as AssetGetter,
  Actions as AssetActions,
} from '@/store/enums/AssetEnums';

import { Mutations as AssetMutations } from '@/store/enums/AssetEnums';

import { Folder } from '@/models/FolderModel';
import store from '@/store';

export default defineComponent({
  components: {
    KTDataTable,
    NewFolder,
    DropDownOption,
  },
  props: {
    newFolderName: {
      type: String,
      default: 'New Folder',
    },
    currentFolder: {
      type: String,
      default: () => {
        return '' as unknown as Folder;
      },
    },
    folders: {
      type: Object,
      default: () => {
        return {} as unknown as Array<Folder>;
      },
    },
    tableLoading: { default: false },
    adding_folder: { default: false },
  },
  data: () => {
    return {
      table: {
        total: 0,
        loading: false,
        items: [],
        search: '',
      },
      uploading: false,
    };
  },
  emits: [
    'onSelect',
    'addingFolder',
    'saveFolder',
    'initBreadcrumbs',
    'onDeleteFolder',
    'onDeleteFile',
    'onViewFile',
  ],
  methods: {
    ...mapActions({
      fetchFolders: AssetActions.FETCH_FOLDERS,
    }),
    onSelect(item) {
      if (item.type !== 'folder') {
        this.$emit('onViewFile', item);
      } else {
        store.commit(AssetMutations.SET_CURRENT_FOLDER, item.id);
        this.$emit('onSelect', item);
        this.$router.push({
          name: 'assets-manager-view',
          params: { id: item.id },
        });
      }
    },
    onDelete(item) {
      if (item.type === 'folder') {
        this.$emit('onDeleteFolder', item);
      } else {
        this.$emit('onDeleteFile', item);
      }
    },
    onSaveFolder(name) {
      this.$emit('saveFolder', name);
    },
  },
  computed: {
    ...mapGetters({
      folderContents: AssetGetter.GET_FOLDERS,
      breadcrumbs: AssetGetter.GET_BREADCRUMBS,
    }),
    addingFolder: {
      get() {
        return this.adding_folder;
      },
      set(value) {
        this.$emit('addingFolder', value);
      },
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        { name: 'Name', key: 'name' },
        { name: 'Size', key: 'size' },
        { name: 'Last Modified', key: 'date_modified', hidden: this.isMobile },
        { name: '', key: 'action' },
      ];
    },
  },
  watch: {
    tableLoading: {
      handler(oldValue, newValue) {
        if (newValue != oldValue) {
          this.table.loading = newValue;
        }
      },
    },
  },
});
